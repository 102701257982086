// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-alfred-jacob-miller-js": () => import("/opt/build/repo/src/pages/alfred-jacob-miller.js" /* webpackChunkName: "component---src-pages-alfred-jacob-miller-js" */),
  "component---src-pages-baptist-health-js": () => import("/opt/build/repo/src/pages/baptist-health.js" /* webpackChunkName: "component---src-pages-baptist-health-js" */),
  "component---src-pages-crossroads-js": () => import("/opt/build/repo/src/pages/crossroads.js" /* webpackChunkName: "component---src-pages-crossroads-js" */),
  "component---src-pages-great-flood-js": () => import("/opt/build/repo/src/pages/great-flood.js" /* webpackChunkName: "component---src-pages-great-flood-js" */),
  "component---src-pages-humana-js": () => import("/opt/build/repo/src/pages/humana.js" /* webpackChunkName: "component---src-pages-humana-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-the-craftery-js": () => import("/opt/build/repo/src/pages/the-craftery.js" /* webpackChunkName: "component---src-pages-the-craftery-js" */)
}

